import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as Parse from "parse";

import { User } from '../user';

@Component({
  selector: 'app-usercreator',
  templateUrl: './usercreator.component.html',
  styleUrls: ['./usercreator.component.css']
})
export class UsercreatorComponent implements OnInit {

  userForm: FormGroup;
  user: User = new User();
  userAdded: boolean;
  userAddError: any;

  constructor() {
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      username: new FormControl(),
      email: new FormControl(),
      security: new FormControl(),
      plate: new FormControl(),
      password: new FormControl()
    });
  }

  get formCtrl() { return this.userForm.controls; }

  save(){
    this.initParse();
    if(this.userForm.value.security == null){
      this.userForm.value.security = false;
    }
    Parse.Cloud.run("createUser", {
      username: this.userForm.value.username, 
      password: this.userForm.value.password, 
      email: this.userForm.value.email, 
      security: this.userForm.value.security, 
      plate: this.userForm.value.plate}).then((result) => {
      this.userAdded = true;
      this.userAddError = "";
    }, (error) => {
      console.log("Error: "+ error);
      this.userAddError = error;
      this.userAdded = false;
    });

    // this.initParse();
    // var user = new Parse.User();
    // user.set("username", this.userForm.value.username);
    // user.set("password", this.userForm.value.password);
    // user.set("email", this.userForm.value.email);

    // user.signUp(null).then((result) => {
    //     this.userAdded = true;
    //     this.userAddError = "";
    //   }, (error) => {
    //     console.log("Error: "+ error);
    //     this.userAddError = error;
    //     this.userAdded = false;
    //   });
  }

  initParse() {
    Parse.initialize("6490ac2f-579b-4571-b2fd-e447c7dcb77b"); 
    (Parse as any).serverURL = 'https://prkingparseserver.azurewebsites.net/parse/'
  }

  reload(){
    window.location.reload();
  }
}
