import { Component, OnInit } from '@angular/core';
import * as Parse from "parse";
import { User } from '../user';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})
export class UserlistComponent implements OnInit {

  users: any;
  userSaved: boolean;
  userSaveError: any;
  pickedUser: any;

  constructor() { }

  ngOnInit() {
    this.initParse();
    this.getUsers();
  }

  getUsers() {
    let query  = new Parse.Query(Parse.User);
    query.notEqualTo("testAccount", true);
    query.notEqualTo("collector", true);
    query.notEqualTo("admin", true);
    query.find().then((result) => {
      this.users = JSON.parse(JSON.stringify(result));
    }, (error) => {
    });
  }

  initParse() {
    Parse.initialize("6490ac2f-579b-4571-b2fd-e447c7dcb77b"); 
    (Parse as any).serverURL = 'https://prkingparseserver.azurewebsites.net/parse/'
  }

  disableUser(userId) {
    Parse.Cloud.run("blockUser", {objectId: userId}).then((result) => {
        this.userSaved = true;
        this.userSaveError = "";
      }, (error) => {
        console.log(error);
        this.userSaveError = error;
        this.userSaved = false;
      });
    // Conventional way to disable user
    // var user = new Parse.User();
    // user.id = objectId;
    // user.set("disabled", true);

    // user.save(null).then((result) => {
    //     this.userSaved = true;
    //     this.userSaveError = "";
    //   }, (error) => {
    //     this.userSaveError = error;
    //     this.userSaved = false;
    //   });
  }

  unblockUser(userId) {
    Parse.Cloud.run("unblockUser", {objectId: userId}).then((result) => {
        this.userSaved = true;
        this.userSaveError = "";
      }, (error) => {
        console.log(error);
        this.userSaveError = error;
        this.userSaved = false;
      });
    // Conventional way to disable user
    // var user = new Parse.User();
    // user.id = objectId;
    // user.set("disabled", false);

    // user.save(null).then((result) => {
    //     this.userSaved = true;
    //     this.userSaveError = "";
    //   }, (error) => {
    //     this.userSaveError = error;
    //     this.userSaved = false;
    //   });
  }

  selectUserForDisabling(user){
    this.pickedUser = user;
  }

  reload(){
    window.location.reload();
  }

}
