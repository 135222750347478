import { Component, OnInit }      from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Ticket } from './ticket';

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.css']
})

export class IssueComponent implements OnInit {
  issueForm: FormGroup;
  ticket: Ticket = new Ticket();

  constructor() { }

  ngOnInit() {
    this.issueForm = new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      phone: new FormControl(),
      email: new FormControl(),
      title: new FormControl(),
      description: new FormControl(),
      attachments: new FormControl()
    });
  }

  save(){
    console.log(this.issueForm);
    console.log('Saved:' + JSON.stringify(this.issueForm.value));
  }

  onSubmit() {

  }

}
