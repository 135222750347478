export class Ticket {
    constructor(
        public firstName = "",
        public lastName = "",
        public phone?: number,
        public email = "",
        public title = "",
        public description = "",
        public attachments = ""
    ) {}
}
