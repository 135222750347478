import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';
import * as Parse from "parse";

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.css']
})
export class TopHeaderComponent implements OnInit {
  isMenuOpened: boolean = false;
  isAlertsOpened: boolean = false;
  currentUser = "";
  parkingTips: any = [];

  constructor(private mainService: MainService) { }

  ngOnInit() {
    this.mainService.initParse();
    this.currentUser = this.mainService.getCurrentUser();
    this.initParse();
    this.getParkingTips();
  }

  logOut() {
    this.mainService.logOut();
    this.currentUser = this.mainService.getCurrentUser();
  }

  toggleMenu() {
    this.isMenuOpened= !this.isMenuOpened;
    this.closeAlerts();
  };

  closeMenu() {
    this.isMenuOpened= false;
  };

  toggleAlerts() {
    this.isAlertsOpened= !this.isAlertsOpened;
    this.closeMenu();
  };

  closeAlerts() {
    this.isAlertsOpened= false;
  };

  getParkingTips() {
    let parkingTipsParse = Parse.Object.extend("ParkingTips");
    let query  = new Parse.Query(parkingTipsParse);
    query.descending("createdAt").limit(3);
    query.find().then((result) => {
      this.parkingTips = JSON.parse(JSON.stringify(result));
    }, (error) => {
      console.log("Error: "+ error);
    });
  }

  initParse(): void {
    Parse.initialize("6490ac2f-579b-4571-b2fd-e447c7dcb77b"); 
    (Parse as any).serverURL = 'https://prkingparseserver.azurewebsites.net/parse/'
  }
}
