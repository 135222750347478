import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from '../users/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  user: User = new User();
  loginSuccess: boolean = true;
  currentUser = "";
  loginStart: boolean = false;

  constructor(private mainService: MainService) { 
    this.loginForm = new FormGroup({
      username: new FormControl(),
      password: new FormControl()
    })
  }

  ngOnInit() {
    this.mainService.initParse();
    this.mainService.routeToDefaultPage();
  }

  logOut() {
    this.mainService.logOut();
  }

  logIn() {
    this.loginStart = true;
    this.mainService.logIn(this.loginForm.value.username, this.loginForm.value.password);
    this.currentUser = this.mainService.getCurrentUser();
    if (this.currentUser != ""){
      window.location.reload();
    }
    setTimeout(() => this.loginFail(), 1000);
    setTimeout(() => this.loginLoading(), 1000);
  }

  loginFail(){
  this.loginSuccess = false;
  }
  loginLoading(){
    this.loginStart = false;
  }
}
