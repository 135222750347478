import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { AppRoutingModule } from './/app-routing.module';
import { ReservationsComponent } from './reservations/reservations.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { AccountComponent } from './account/account.component';
import { IssueComponent } from './issue/issue.component';
import { SpotsComponent } from './statistics/spots/spots.component';
import { CalendarComponent } from './statistics/calendar/calendar.component';
import { TimePickerComponent } from './statistics/time-picker/time-picker.component';
import { RevenueComponent } from './statistics/revenue/revenue.component';
import { UsersComponent } from './users/users.component';
import { UserlistComponent } from './users/userlist/userlist.component';
import { UsercreatorComponent } from './users/usercreator/usercreator.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    TopHeaderComponent,
    ReservationsComponent,
    StatisticsComponent,
    AccountComponent,
    IssueComponent,
    SpotsComponent,
    CalendarComponent,
    TimePickerComponent,
    RevenueComponent,
    UsersComponent,
    UserlistComponent,
    UsercreatorComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
