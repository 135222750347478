import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountComponent } from './account/account.component';
import { IssueComponent } from './issue/issue.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { UsersComponent } from './users/users.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '/spots', pathMatch: 'full'},
  // { path: 'account', component: AccountComponent},
  // { path: 'issue', component: IssueComponent},
  { path: 'login', component: LoginComponent },
  { path: 'spots', component: ReservationsComponent},
  { path: 'users', component: UsersComponent},
];

@NgModule({
  imports: [ 
    RouterModule.forRoot(routes) 
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
