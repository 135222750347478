import { Injectable } from '@angular/core';

import * as Parse from "parse"
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  
  userLogged: boolean;

  constructor(private router: Router) {
  }

  initParse(): void {
    Parse.initialize("6490ac2f-579b-4571-b2fd-e447c7dcb77b"); 
    (Parse as any).serverURL = 'https://prkingparseserver.azurewebsites.net/parse/'
  }

  getCurrentUser(){
    if (Parse.User.current() == null){
      //route to login page
      return "";
    } else {
      return Parse.User.current().getUsername();
    }

  }

  routeToLogin(){
    if (Parse.User.current() == null){
      this.router.navigate(['/login']);
    }
  }

  routeToDefaultPage(){
    if (Parse.User.current() != null){
      this.router.navigate(['/spots']);
    }
  }

  logOut(){
    Parse.User.logOut();
    window.location.reload();
  }

  logIn(username, password){
    Parse.User.logIn(username, password).then((result) => {
      this.userLogged = true;
      if(Parse.User.current().get("admin")){
        window.location.reload();
      }
      else{
        Parse.User.logOut();
        this.userLogged = false;
      }

    }, (error) => {
      console.log("Error:"+ error);
      this.userLogged = false;
    });
  }
}