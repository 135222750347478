import { Component, OnInit } from '@angular/core';
import { ParkingSpot } from './parking-spot';
import { MainService } from '../main.service';
import { ParkingTips } from '../parkingTips';
import * as Parse from "parse";

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})

export class ReservationsComponent implements OnInit {

  //selectedSpot: ParkingSpot = {id: 1};
  selectedSpot = {} as ParkingSpot;
  // parkingSpots: ParkingSpots;
  parkingTips: ParkingTips[];
  result;
  spots;
  parking = {};
  parkingSpots: any = [];

  constructor(private mainService: MainService) { }

  ngOnInit() {
    //this.mainService.initParse();
    //this.result = this.mainService.getParkingTips();
    // this.parkingPlain = Object.assign({}, this.mainService.getParkingPlain());
    //this.parkingPlain = this.mainService.getParkingPlain();

    // Parse.initialize("6490ac2f-579b-4571-b2fd-e447c7dcb77b"); 
    // (Parse as any).serverURL = 'https://prkingparseserver.azurewebsites.net/parse/'
    // let parkingSensor = Parse.Object.extend("ParkingSensor");
    // let query  = new Parse.Query(parkingSensor);

    // query.find().then((result) => {
    //   this.parkingPlain = JSON.parse(JSON.stringify(result));
    //     }, (error) => {
    //   });
    this.initParse();
    this.getParkingSpots();
    this.mainService.routeToLogin();
  }

  initParse() {
    Parse.initialize("6490ac2f-579b-4571-b2fd-e447c7dcb77b"); 
    (Parse as any).serverURL = 'https://prkingparseserver.azurewebsites.net/parse/'
  }

  selectSpot(objectId) {
    this.selectedSpot = this.parkingSpots.find(x => x.objectId === objectId);
  };
  
  getParkingSpots() {
    let parkingSensor = Parse.Object.extend("ParkingSensor");
    let query  = new Parse.Query(parkingSensor);
    query.include("occupiedBy");
    query.find().then((result) => {
      this.parkingSpots = JSON.parse(JSON.stringify(result));
    }, (error) => {
    });
  }

  getSpotColor(occupied, occupiedBy) {
    if (!occupied){
      return "#40B5A1";
    }
    else if (occupied && occupiedBy != null){
      return "#F15E22";
    }
    else if (occupied && occupiedBy == null){
      return "#F8E71C";
    }
    return "#FFFFFF";
  }

  getTotalSpots(): number {
    return this.parkingSpots.length;
  }

  getFreeSpots(): number {
    let freeSpots = 0;
    for (let spot of this.parkingSpots){
      if (!spot.occupied){
        freeSpots++;
      }
    }
    return freeSpots;
  }

  getFreeSpotsPercentage(): number {
    return this.getFreeSpots()*100/this.getTotalSpots();
  }

}
